// TBZ,TTBB 공통 상수

/* ----- 기본 글로벌 세팅 ----- */
// 디폴트 체크인 체크아웃 기간 추가값
export const DEFAULT_SETTING_DATE_START = 7;
export const DEFAULT_SETTING_DATE_END = 8;

/* ----- 검색 관련 영역 세팅 ----- */
// 검색 영역 입력 딜레이
export const SEARCH_INPUT_DELAY = 50;

// 검색 영역 아이콘 타입
export const IC_TYPE = {
  RECOMMEND: ["recommend_hotel", "event"],
  HOTEL: ["hotel", "HOTEL"],
  LANDMARK: ["point_of_interest", "region_poi"],
  TRAIN: ["train_station", "region_trainstaion"],
  SUBWAY: ["metro", "region_metro"],
  AIRPORT: ["airport", "region_airport"],
  LOCATE: [
    "city",
    "country",
    "high_level_region",
    "multi_city_vicinity",
    "neighborhood",
    "province_state",
    "town",
    "recommend_region",
    "region",
  ],
};

// 검색 영역 키워드 타입
export const TYPE = {
  HOTEL: "hotel",
  MD: "md",
  REGION: "region",
};

// 추천 키워드 영역 랜딩 타입
export const LANDING_TYPE = {
  MD_DETAIL: "md_detail",
  HOTEL_DETAIL: "host_detail",
  TYPE_SEARCH: "type_search",
  region_SEARCH: "region_search",
};

export const SEARCH_TYPE_ALLOW_MAP = ["region", "REGION", "city"];

export const SEARCH_TYPE_TINVEN = "tinven";
export const SEARCH_TYPE = ["keyword", SEARCH_TYPE_TINVEN];

export const FILTER_LIST = {
  HOTEL: "호텔",
  RESORT: "리조트",
  MOTEL: "모텔",
  OTHER: "기타숙소",
};

// 빅배너 타입
export const BIG_BANNER_TYPE = {
  BANNER_A: "BANNER_A",
  BANNER_B: "BANNER_B",
  BANNER_C: "BANNER_C",
};

export const BIG_BANNER_ITEM_CONTENTS_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
};

export const MAIN_BANNER_KEYWORD_TAB_TYPE = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  RECENT: "RECENT",
};

/* ----- 호텔 관련 영역 세팅 ----- */
export const META_SOURCE_LIST_KO = {
  NAVER: "네이버 호텔",
  GOOGLE: "구글",
  HOTELSCOMBINED: "호텔스컴바인",
  KAYAK: "카약",
};

export const META_SOURCE_LIST_EN = {
  NAVER: "Naver",
  GOOGLE: "Google",
  HOTELSCOMBINED: "HotelsCombined",
  KAYAK: "KAYAK",
};

// 숙소 리스트 프로모션 레이블 타입
export const PROMOTION_LABEL_TYPE = {
  TIME_DEAL: "TIME DEAL",
  HOT_DEAL: "HOT DEAL",
};

export const OCCUPANCY = {
  BASE: 2,
};

/* ----- 결제 관련 영역 세팅 --- */
// 예약 및 환불 안내 타입
export const REFUND_TYPE = {
  FREE: "FREE",
  PENALTY: "PENALTY",
  NONE: "NONE",
};

// 결제 모듈 타입
export const CHECKOUT_PG_TYPE = {
  NICE: "nice",
  KAKAO: "kakao",
  NAVER: "naverpay",
  KCP: "kcp",
};

// 결제 상태
export const CHECKOUT_STATUS = {
  PROCESSING: "PROCESSING",
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
};

// 결제 수단
export const TOSS_QUICK_PAY = "tossQuick";
export const TOSS_QUICK_PAY_TYPE = "toss.quickAccount";

/* ----- 마이페이지 및 예약 내역 관련 영역 세팅 --- */
// 이벤트 알림 설정 타입
export const EVENT_ALARM = {
  POLICY: "policy",
  EMAIL: "email",
  SMS: "sms",
};

// 예약 상태
export const BOOKING_STATUS = {
  CONFIRM: "confirm",
  STAYED: "stayed",
  CANCELLED: "cancelled",
};

// 크루픽 작성 상태
export const CREWPICK_STATUS = {
  WRITEABLE: "WRITEABLE",
  DONE: "DONE",
};

/* ----- MD 관련 영역 세팅 --- */
// MD 각 아이템별 타입
export const MD_TYPE = {
  IMAGE: "IMAGE",
  HOTEL_LIST: "HOTEL_LIST",
  REGION_HOTEL_LIST: "REGION_HOTEL_LIST",
  VIDEO: "VIDEO",
};

// MD 내 VIDEO 타입
export const MD_VIDEO_TYPE = {
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
};

/* ----- 기타 ----- */
// 숙박서비스 제공업체 한 페이지에 보여줄 개수
export const PLACELIST_SIZE = 40;

/* ----- 약관 팝업 ----- */
// 약관 타입
export const TERMS_TYPE = {
  SERVICE: "service",
  WITHDRAW_SERVICE: "wservice",
  PRIVACY: "privacy",
  AD: "ad",
  THIRD: "third",
  CARD: "card",
};

// 약관 사용 페이지 종류
export const TERMS_CATEGORY = {
  SIGN_UP: "signup",
  QUICK_SIGN_UP: "quick",
  PAYMENT: "payment",
  WITHDRAW: "withdraw",
  MYPAGE: "mypage",
  SSF: "ssf",
};

// 약관 팝업 모드
export const TERMS_POPUP_MODE = {
  FULL: "full",
  POPUP: "popup",
  LABEL: "label",
  APP: "app",
};
