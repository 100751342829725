import axios from "axios";
import AuthAxios from "./AuthAxios";
import MakeHeaders from "@modules/utils/makeHeaders";
// import { captureException } from "@sentry/nextjs";

export const axiosSetting = {
  scheme: process.env.NEXT_PUBLIC_API_BASE_SCHEME,
  host: process.env.NEXT_PUBLIC_API_BASE_HOST,
  port: process.env.NEXT_PUBLIC_API_BASE_PORT,
  server() {
    return `${this.scheme ? `${this.scheme}:` : ""}//${this.host}${
      this.port ? `:${this.port}` : ""
    }`;
  },
};

export const pushAxiosSetting = {
  scheme: process.env.NEXT_PUBLIC_API_BASE_SCHEME,
  host: process.env.NEXT_PUBLIC_API_PUSH_HOST,
  port: process.env.NEXT_PUBLIC_API_BASE_PORT,
  server() {
    return `${this.scheme ? `${this.scheme}:` : ""}//${this.host}${
      this.port ? `:${this.port}` : ""
    }`;
  },
};

const isTTBB = process.env.NEXT_PUBLIC_DOMAIN.includes("ttbb");

export const ApiAxios = axios.create({
  baseURL: axiosSetting.server(),
  headers: MakeHeaders({
    "Content-Type": "application/json",
    "x-tripbtoz-channel": isTTBB ? "TTBB" : "TBZ",
  }),
});

export const PushApiAxios = axios.create({
  baseURL: pushAxiosSetting.server(),
  headers: MakeHeaders({
    "Content-Type": "application/json",
    "x-tripbtoz-channel": isTTBB ? "TTBB" : "TBZ",
  }),
});
/*AuthAxios.interceptors.request.use((request) => {
  console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});*/
ApiAxios.interceptors.response.use(
  res => res,
  async err => {
    const {
      config,
      response: { status },
    } = err;
    if (status !== 401 || config.sent || !config.headers.refreshToken) {
      // captureException(
      //   {
      //     name: "ApiAxios_ERROR",
      //     message: err.code,
      //   },
      //   {
      //     ...config,
      //   },
      // );
      return Promise.reject(err);
    }
    config.sent = true;
    const response = await AuthAxios.post(
      `/auth/token/refresh`,
      {
        refresh_token: config.headers.refreshToken,
      },
      {
        headers: MakeHeaders({
          "Accept-Language": config.headers["Accept-Language"],
          "User-Agent": config.headers["User-Agent"],
        }),
      },
    );
    if (response.status !== 200) {
      return Promise.reject(err);
    } else {
      // console.log(response.data);
      config.headers.Authorization = `Bearer ${response.data.access_token}`;
      config.headers.token = JSON.stringify(response.data);
      config.headers.refreshToken = response.data.refresh_token;
    }

    return axios(config);
  },
);
export default ApiAxios;
