import axios from "axios";
import MakeHeaders from "@modules/utils/makeHeaders";
// import { captureException } from "@sentry/nextjs";
import ApiAxios from "@modules/client/PaymentsAxios";

export const AuthAxiosSetting = {
  scheme: process.env.NEXT_PUBLIC_AUTH_BASE_SCHEME,
  host: process.env.NEXT_PUBLIC_AUTH_BASE_HOST,
  port: process.env.NEXT_PUBLIC_AUTH_BASE_PORT,
  server() {
    return `${this.scheme ? `${this.scheme}:` : ""}//${this.host}${
      this.port ? `:${this.port}` : ""
    }`;
  },
};
// console.log(`API URL = ${AuthAxiosSetting.server()}`);
const isTTBB = process.env.NEXT_PUBLIC_DOMAIN.includes("ttbb");
export const AuthAxios = axios.create({
  baseURL: AuthAxiosSetting.server(),
  headers: MakeHeaders({
    "Content-Type": "application/json",
    "x-tripbtoz-channel": isTTBB ? "TTBB" : "TBZ",
  }),
});
/*AuthAxios.interceptors.request.use((request) => {
  console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});*/
ApiAxios.interceptors.response.use(
  res => res,
  async err => {
    const {
      config,
      response: { status },
    } = err;
    // captureException(
    //   {
    //     name: "AuthAxios_ERROR",
    //     message: err.code,
    //   },
    //   {
    //     ...config,
    //   },
    // );
    return Promise.reject(err);
  },
);
export default AuthAxios;
