import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ko";
dayjs.locale("ko");
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

/**
 * 지니티비 타임딜을 위한 디폴트 일정 변경
 * @param value
 * @param type
 * @returns {*|dayjs.Dayjs}
 */
export const searctDaySetting = (value, type) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Asia/Seoul");

  const tempSettingStart = dayjs("2024-01-05");
  const tempSettingEnd = dayjs("2024-01-31");

  if (dayjs().isBetween(tempSettingStart, tempSettingEnd)) {
    return type === "start" ? dayjs("2024-02-26") : dayjs("2024-02-27");
  } else {
    return value;
  }
};

/**
 * 지니티비 타임딜 호텔
 */
export const GENIE_TIMEDEAL_HOTEL = [
  "1821208",
  "1530330",
  "10102255",
  "1584483",
  "10115014",
  "1474775",
  "1797911",
  "10229714",
];

/**
 * MD 특정 이미지 item 클릭시 url copy
 */
export const EVENT_MD = [
  {
    name: "sflove",
    number: 8,
  },
  {
    name: "sflove_m",
    number: 7,
  },
];
