import { AD_TYPE, SOURCE_NAME, STORAGE_KEY } from "@modules/utils/Adsource";
import { getSessionStorage } from "@modules/utils/storage";
import { Cookies } from "react-cookie-consent";
import { result } from "lodash-es";

export const getMetaSource = query => {
  let result = "";

  if (query.meta_source) result = query.meta_source;

  if (!result) {
    if (query.utm_source === AD_TYPE.GOOGLE) result = "google";
    if (query.utm_source === "SP_NAVER") result = "naver_shop";
  }

  return result;
};

export const getMeta = query => {
  let result = "";

  /**
   * 로컬 스토리지 체크
   */
  const LSData = localStorage.getItem(STORAGE_KEY.T_META_KEY);
  if (LSData) {
    const LS = JSON.parse(LSData);
    if (LS.meta_type) result = LS.meta_type;

    if (
      LS.meta_type === "SEO_NAVER" ||
      LS.meta_type === "SEO_GOOGLE" ||
      LS.meta_type === AD_TYPE.THREE_HOURS
    ) {
      result = LS.meta_type;
    } else if (LS.marketing_meta === SOURCE_NAME.KAYAK) {
      result = "KAYAK";
    } else if (LS.meta_type === "SP_NAVER") {
      result = LS.meta_type;
    } else if (Object.values(AD_TYPE).includes(LS.meta_type)) {
      result = LS.meta_type;
    } else if (LS.meta_type.includes(AD_TYPE.GOOGLE)) {
      result = "google";
    }
  }

  /**
   * 세션 스토리지 체크
   */
  if (getSessionStorage(AD_TYPE.TOSSPAY_CPS.toLowerCase())) {
    result = AD_TYPE.TOSSPAY_CPS;
  }

  /**
   * 쿠키 체크
   */
  if (Cookies.get("shopback")) {
    result = AD_TYPE.SHOPBACK;
  }

  if (query) {
    if (query.utm_source?.includes(AD_TYPE.GOOGLE)) result = "google";
    if (query.utm_source === "SP_NAVER") result = "naver_shop";
  }

  return result;
};
